import { Button } from 'antd';
import { dateFormats, scheduleNames } from "../../../common/Constants";
import { numberOfAcademicWeek } from '../js/schedule-scripts';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Title from "antd/es/typography/Title";
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchSchedule, scheduleSlice, fetchScheduleCombined } from '../../../reducers/scheduleSlice';
import { getMatchParams } from '../../../js/getMatchParams';

function HeaderOfSchedule() {

    const { startDate, endDate, isCombinedScheduleActive, numOfActiveSchedules } = useAppSelector(state => state.scheduleReducer);
    const { numOfChoosen } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { showNextWeek, showPrevWeek } = scheduleSlice.actions;
    const dispatch = useAppDispatch();

    const matchParams = getMatchParams();
    function prevWeekHandle() {

        dispatch(showPrevWeek());
        if (numOfChoosen) {
            dispatch(fetchScheduleCombined())
        }
        else {
            dispatch(fetchSchedule())
        }
    }

    function nextWeekHandle() {

        dispatch(showNextWeek());

        if (numOfChoosen) {
            dispatch(fetchScheduleCombined())
        }
        else {
            dispatch(fetchSchedule())
        }
    }

    return (
        <div>
            <Title
                level={2}>Расписание {scheduleNames[matchParams.type as keyof typeof scheduleNames]} {matchParams.name} {isCombinedScheduleActive ? `(+${numOfActiveSchedules})` : ''}</Title>
            <div className={"date-range"}>
                {startDate.format(dateFormats.DAY_MONTH_YEAR)} – {endDate.format(dateFormats.DAY_MONTH_YEAR)} • {numberOfAcademicWeek(endDate)}-я неделя
            </div>
            <Button
                style={{ marginRight: 16 }}
                onClick={() => { prevWeekHandle() }}
            >
                <ArrowLeftOutlined />
                Предыдущая неделя
            </Button>

            <Button onClick={() => { nextWeekHandle() }}>
                Следующая неделя
                <ArrowRightOutlined />
            </Button>
        </div>
    )
}

export default HeaderOfSchedule;