import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { localStorageKeys, UserRoles } from "../common/Constants";
import { handle } from "../common/ErrorHandler";
import { notifySuccess } from "../common/NotificationUtility";
import URLPaths from "../common/URLPaths";
import { IBookingRequestFull, ITsuAccountProfile, IUserProfile } from "../models/Models";
import Network from "../services/Network";


interface ProfileState {
    isLoading: boolean,
    profile: ITsuAccountProfile,
    isSubscribedForUpdates: boolean,
    subscriptionEmail: string | null,
    isSaveButtonDisabled: boolean,
    canSubscribe: boolean,
    canBook: boolean,
    bookingHistory: IBookingRequestFull[],
    visiblePopoverIndex: number | null
}

const initialState: ProfileState = {
    isLoading: false,
    profile: JSON.parse(localStorage.getItem(localStorageKeys.USER_PROFILE) as string)?.tsuAccountProfile,
    isSubscribedForUpdates: false,
    subscriptionEmail: null,
    isSaveButtonDisabled: true,
    canSubscribe: false,
    canBook: false,
    bookingHistory: [],
    visiblePopoverIndex: null
}



export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setIsSubscribedForUpdates(state, action: PayloadAction<boolean>) {
            state.isSubscribedForUpdates = action.payload;
        },
        setSubscribtionEmail(state, action: PayloadAction<string>) {
            state.subscriptionEmail = action.payload;
        },
        setCanSubscribe(state, action: PayloadAction<boolean>) {
            state.canSubscribe = action.payload;
        },
        setCanBook(state, action: PayloadAction<boolean>) {
            state.canBook = action.payload;
        },
        setBookingHistory(state, action: PayloadAction<IBookingRequestFull[]>) {
            state.bookingHistory = action.payload;
        },
        setVisiblePopoverIndex(state, action: PayloadAction<number | null>) {
            state.visiblePopoverIndex = action.payload;
        },
        setProfile(state, action: PayloadAction<{ profile: ITsuAccountProfile, subscriptionEmail: string, isSubscribedForUpdates: boolean }>) {
            state.profile = action.payload.profile;
            state.subscriptionEmail = action.payload.subscriptionEmail;
            state.isSubscribedForUpdates = action.payload.isSubscribedForUpdates;
        },
        checkCanSubscribeAndCanBook(state, action: PayloadAction<IUserProfile>) {
            const userRoles = action.payload.roles;
            state.canSubscribe = (userRoles.includes(UserRoles.STAFF) || userRoles.includes(UserRoles.ADMIN))
            state.canBook = (userRoles.includes(UserRoles.STAFF) || userRoles.includes(UserRoles.ADMIN))
        },
        updateButton(state, action: PayloadAction<any>) {
            state.isSaveButtonDisabled = action.payload.isSubscribedForUpdates && (!action.payload.subscriptionEmail || action.payload.subscriptionEmail === "")
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchCancelBooking.fulfilled, (state) => {
            notifySuccess("Успешно", "Заявка на бронь аудитории была отменена");
        })
        builder.addCase(fetchCancelBooking.rejected, (state) => {
            handle();
        })
        builder.addCase(fetchProfile.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.isLoading = action.payload.shouldLoading;
            const profile = action.payload.profile;
            state.isSubscribedForUpdates = profile.hasNotificationSubscription
            state.subscriptionEmail = profile.notificationSubscriptionEmail;
            state.profile = profile.tsuAccountProfile;
        })
        builder.addCase(fetchProfile.rejected, (state) => {
            state.isLoading = false;
            handle();
        })

        builder.addCase(fetchBookings.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchBookings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.bookingHistory = action.payload.bookings;
        })
        builder.addCase(fetchBookings.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
        builder.addCase(saveData.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(saveData.fulfilled, (state) => {
            state.isLoading = false;
            notifySuccess("Успешно", "Информация обновлена");
        })
        builder.addCase(saveData.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
    },
})

export const fetchProfile = createAsyncThunk(
    "profile/fetchProfile",

    async function (_: void, thunkAPI) {
        const responseProfile = await Network.request(URLPaths.userProfile);
        const profileData = responseProfile.data as IUserProfile;
        let shouldLoading = false;
        if (profileData.roles.includes(UserRoles.ADMIN) || profileData.roles.includes(UserRoles.STAFF)) {
            thunkAPI.dispatch(fetchBookings());
            shouldLoading = true;
        }

        return { profile: profileData, shouldLoading };
    }
);

export const fetchCancelBooking = createAsyncThunk(
    "profile/fetchCancelBooking",

    async function (record: IBookingRequestFull, thunkAPI) {
        await Network.request(URLPaths.cancelBooking(record.id), "POST");
        thunkAPI.dispatch(fetchBookings())
    }
);

const fetchBookings = createAsyncThunk(
    "profile/fetchBookings",

    async function () {
        const responseBookings = await Network.request(URLPaths.bookings);
        return { bookings: responseBookings.data as IBookingRequestFull[] };
    }
);

export const saveData = createAsyncThunk(
    "profile/saveData",

    async function (data: { isSubscribedForUpdates: boolean, subscriptionEmail: string | null }) {
        let response;
        if (data.isSubscribedForUpdates) {
            const param: any = { email: data.subscriptionEmail }
            response = await Network.request(URLPaths.notificationSubscribe, "POST", {}, param)
        }
        else {
            const param: any = {}
            response = await Network.request(URLPaths.notificationUnsubscribe, "POST", {}, param)
        }

        return response.data;
    }
);

export function finishLogin() {
    const query = new URLSearchParams(window.location.search);

    Network.request(URLPaths.loginTsuAccount, "post", {}, { token: query.get("token") } as any)
        .then((response: any) => {
            localStorage.setItem(localStorageKeys.TOKEN_ACCESS, response.data.token.accessToken);
            localStorage.setItem(localStorageKeys.TOKEN_REFRESH, response.data.token.refreshToken);
            localStorage.setItem(localStorageKeys.USER_PROFILE, JSON.stringify(response.data.profile));
            window.location.href = "/profile";
        }).catch(() => {
            handle();
        });
}

export default profileSlice.reducer;