import React, { useEffect } from "react";
import "./groups.scss";
import { Button, Col, Row, Spin, Typography } from 'antd';
import FacultySelectorContainer from "./components/FacultySelector";
import GroupSelectorContainer from "./components/GroupSelector";
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchFaculties } from '../../reducers/groupsSlice';
import { useNavigate } from "react-router-dom";
import { schedulePaths } from "../../common/URLPaths";
const { Title } = Typography;

function Groups() {

    const dispatch = useAppDispatch();
    const { groups, isLoading, selectedFacultyId, selectedGroupId } = useAppSelector(state => state.groupsReducer)
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchFaculties());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={16}>
                <Spin size="large" spinning={isLoading}>
                    <div className={"groups_content-container"}>
                        <Title level={2}>Расписание групп</Title>
                        <FacultySelectorContainer />
                        <GroupSelectorContainer />
                        {
                            selectedFacultyId != null && selectedGroupId != null ?
                                <Button type="primary" size={"large"}
                                    style={{ marginTop: 32 }} onClick={() => {
                                        navigate(schedulePaths.getInitPathGroup(selectedGroupId, groups))
                                    }}>
                                    Показать расписание
                                </Button> : null
                        }
                    </div>
                </Spin>
            </Col>
        </Row>
    );
}

export default Groups;