/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, Drawer, Segmented, Button, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import AuditoriesTabContainer from './Components/AuditoriesTab';
import ChhosenTabContainer from './Components/ChoosenTab';
import GroupsTabContainer from './Components/GroupsTab';
import ProfessorsTabContainer from './Components/ProfessorsTab';
import './css/scheduleDrawer.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { combinedScheduleDrawerSlice } from '../../../../reducers/combinedScheduleDrawerSlice';
import { fetchSchedule, fetchScheduleCombined } from '../../../../reducers/scheduleSlice';

function CombinedScheduleDrawer() {

    const { isVisible, numOfChoosen } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { isCombinedScheduleActive } = useAppSelector(state => state.scheduleReducer);
    const { setIsVisible, saveActiveArrays, resetActiveArrays } = combinedScheduleDrawerSlice.actions;
    const dispatch = useAppDispatch();

    const [curTab, setCurTab] = useState('groups')




    useEffect(() => {
        if (isVisible) {
            dispatch(saveActiveArrays());
        }

    }, [isVisible])

    function loadSchedule() {
        dispatch(fetchSchedule())
    }

    function loadCombinedSchedule() {
        dispatch(fetchScheduleCombined())
        dispatch(setIsVisible(false));
    }

    function onCloseHandler() {
        dispatch(resetActiveArrays());
        dispatch(setIsVisible(false));
    }

    function searchChildren() {

        const tabs = [
            { label: '', key: 'groups', children: <GroupsTabContainer /> },
            { label: '', key: 'professors', children: <ProfessorsTabContainer /> },
            { label: '', key: 'auditories', children: <AuditoriesTabContainer /> }
        ];

        return (
            <>
                <Segmented
                    block
                    options={[
                        {
                            label: (
                                <div onClick={() => { setCurTab('groups') }} >
                                    Группы
                                </div>
                            ),
                            value: '1'
                        },
                        {
                            label: (
                                <div onClick={() => { setCurTab('professors') }}>
                                    Преподаватели
                                </div>
                            ),
                            value: '2'
                        },
                        {
                            label: (
                                <div onClick={() => { setCurTab('auditories') }}>
                                    Аудитории
                                </div>
                            ),
                            value: '3'
                        }
                    ]}
                />

                <Tabs
                    defaultActiveKey='2'
                    activeKey={curTab}
                    tabBarStyle={{ display: 'none' }}
                    items={tabs}
                />
            </>
        )
    }

    function choosenLabel() {
        return (
            <Badge
                offset={[0, 7]}
                color={'blue'}
                count={numOfChoosen}
            >
                <div
                    style={{ transitionDuration: '0.2s', paddingRight: numOfChoosen > 0 ? '16px' : '0px' }}
                >
                    Выбранные
                </div>
            </Badge>
        )
    }

    const tabItems = [
        { label: 'Поиск', key: 'search', children: searchChildren() },
        { label: choosenLabel(), key: 'choosen', children: <ChhosenTabContainer /> }
    ];

    return (
        <Drawer
            title="Объединить расписания"
            open={isVisible}
            onClose={() => onCloseHandler()}
            width={'none'}
            footer={
                <Button
                    type="primary"
                    block
                    onClick={() => !numOfChoosen && isCombinedScheduleActive ? loadSchedule() : loadCombinedSchedule()}
                    disabled={numOfChoosen || isCombinedScheduleActive ? false : true}
                >
                    {!numOfChoosen && isCombinedScheduleActive ? 'Отменить объединение' : 'Объединить'}
                </Button>
            }
        >
            <div style={{ flexGrow: '1' }}>
                <Tabs defaultActiveKey='1' items={tabItems} />
            </div>
        </Drawer >
    )
}

export default React.memo(CombinedScheduleDrawer);