import React from "react";
import { Select, Typography } from "antd";
import '../../../css/defaultSelector.scss';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { professorsSlice } from "../../../reducers/professorsSlice";
import { filterOptionForSelector } from "../../../js/inputFilters";
const { Title } = Typography;
const { Option } = Select;

function ProfessorsSelector() {

    const { selectedProfessorId, professors } = useAppSelector(state => state.professorsReducer)
    const { setSelectedProfessor } = professorsSlice.actions;
    const dispatch = useAppDispatch();

    function onProfessorChange(selectedProfessorId: string) {
        dispatch(setSelectedProfessor(selectedProfessorId))
    }

    return (
        <>
            <Title level={5}>Преподаватель:</Title>
            <Select
                showSearch
                defaultValue={selectedProfessorId}
                className="defaultSelector"
                placeholder="ФИО преподавателя"
                optionFilterProp="children"
                onChange={onProfessorChange}
                filterOption={(input, option) => filterOptionForSelector(input, option)}
            >
                {
                    professors.map(professor => {
                        return <Option key={professor.id} value={professor.id}>{professor.fullName}</Option>
                    })
                }
            </Select>
        </>
    )
}

export default ProfessorsSelector;