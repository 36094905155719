import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { ReactComponent as TsuLogo } from "../../images/tsu_logo_small.svg";
import URLPaths from "../../common/URLPaths";

const { Title, Paragraph } = Typography;

function Login() {

    return (
        <Row justify="center" align="middle">
            <Col xs={24} sm={16} md={12} lg={8}>
                <div className={"home_content-container"}>
                    <Title level={2}>Авторизация</Title>
                    <Paragraph>
                        Войдите при помощи своего ТГУ.Аккаунта, чтобы получить дополнительные функции при работе с расписанием.
                    </Paragraph>
                    <Button type="primary" style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}
                        icon={<TsuLogo style={{ width: 24, height: 24, marginRight: 8, marginLeft: -8 }} />} size="large"
                        onClick={() => {
                            window.location.href = URLPaths.loginApplicationId;
                        }}>
                        Войти через ТГУ.Аккаунт
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default Login;
