import React, { useEffect } from "react";
import { Col, Row, Spin, Button, Tabs, Table, Tag, Popover } from "antd";
import { UnorderedListOutlined } from '@ant-design/icons';
import "./profile.scss";
import moment, { Moment } from "moment";
import 'moment/locale/ru';
import NotificationTabContainer from "./components/NotificationTab";
import AvatarAndExitButton from "./components/AvatarAndExitButton";
import { dateFormats, localStorageKeys } from "../../common/Constants";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchCancelBooking, fetchProfile, profileSlice } from "../../reducers/profileSlice";
import { IAuditory, IBookingFormField, IBookingRequestFull, ILesson, IUserProfile } from "../../models/Models";

const TabPane = Tabs.TabPane;

function Profile() {

    const { profile, bookingHistory, visiblePopoverIndex, isLoading, canBook } = useAppSelector(state => state.profileReducer);
    const { setVisiblePopoverIndex, checkCanSubscribeAndCanBook } = profileSlice.actions;
    const dispatch = useAppDispatch();

    function onOpenChangeCancelBooking(isOpen: boolean) {
        if (!isOpen) {
            dispatch(setVisiblePopoverIndex(null));
        }
    }
    const additionalFieldsColumns = [
        {
            title: 'Название',
            dataIndex: 'field',
            key: 'field',
            render: (field: IBookingFormField) => field.title
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (value: string) => value
        }
    ]

    const bookingTableColumns = [
        {
            title: 'Мероприятие',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: IBookingRequestFull) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            {text}
                        </div>
                        {
                            record.additionalFields.length > 0 &&
                            <Popover
                                placement="right"
                                title="Дополнительные поля"
                                content={
                                    <Table columns={additionalFieldsColumns} dataSource={record.additionalFields} />
                                }
                            >
                                <UnorderedListOutlined style={{ cursor: 'pointer' }} />
                            </Popover>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (date: Moment) => moment(date).format(dateFormats.DAY_MONTH_YEAR)
        },
        {
            title: 'Время',
            dataIndex: 'lessons',
            key: 'lessons',
            render: (lessons: ILesson[]) => {

                return (
                    <div className="time-pill-wrapper">
                        {lessons.map(lesson => {
                            return (
                                <div className="time-pill-profile">
                                    {moment.unix(lesson.start).format(dateFormats.HOUR_MINUTES)} – {moment.unix(lesson.end).format(dateFormats.HOUR_MINUTES)}
                                </div>
                            )
                        })}
                    </div>
                )
            }
        },
        {
            title: 'Место',
            dataIndex: 'audience',
            key: 'audience',
            render: (audience: IAuditory) => audience.name
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                switch (status) {
                    case 'NEW':
                        return <Tag color="blue">Новый</Tag>;
                    case 'APPROVED':
                        return <Tag color="green">Одобрено</Tag>;
                    case 'REJECTED':
                        return <Tag color="red">Отклонено</Tag>;
                    case 'CANCELED':
                        return <Tag color="volcano">Отменено</Tag>;
                    case 'REQUIRE_APPROVE':
                        return <Tag color="orange">Требуется согласование</Tag>;
                    default:
                        return null;
                }
            }
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: IBookingRequestFull, index: number) => {
                if (record.status === "CANCELED" || record.status === "REJECTED") {
                    return null;
                }
                return (
                    <Popover
                        open={visiblePopoverIndex === index}
                        onOpenChange={(isOpen) => onOpenChangeCancelBooking(isOpen)}
                        content={() => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                    <Button
                                        danger
                                        type="text"
                                        onClick={() => { cancelBooking(record) }}
                                    >
                                        Да, отменить
                                    </Button>

                                    <Button
                                        type="text"
                                        style={{ color: '#096DD9' }}
                                        onClick={() => { dispatch(setVisiblePopoverIndex(null)) }}
                                    >
                                        Нет
                                    </Button>
                                </div>
                            )
                        }} title="Вы уверены, что хотите отменить бронь?" trigger="click">
                        <Button
                            danger
                            onClick={() => dispatch(setVisiblePopoverIndex(index))}
                        >
                            Отменить
                        </Button>
                    </Popover>
                )
            }
        }
    ];

    useEffect(() => {
        moment.locale("ru");
        let profile: IUserProfile = JSON.parse(localStorage.getItem(localStorageKeys.USER_PROFILE) as string);
        if (profile && profile.roles) {
            dispatch(checkCanSubscribeAndCanBook(profile))
        }
        dispatch(fetchProfile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function cancelBooking(record: IBookingRequestFull) {

        dispatch(setVisiblePopoverIndex(null));
        dispatch(fetchCancelBooking(record));
    }

    return (
        <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={20} lg={16}>
                <div className={"home_content-container"}>
                    {
                        profile === null ?
                            <Spin style={{ display: "block", margin: "0 auto" }} size="large" /> :
                            <Spin size={"large"} spinning={isLoading}>
                                <AvatarAndExitButton />
                                <Tabs defaultActiveKey="1" style={{ marginTop: 16 }}>
                                    <TabPane tab="Уведомления" key="1">
                                        <NotificationTabContainer />
                                    </TabPane>

                                    <TabPane tab="Бронирования" key="2">
                                        {
                                            canBook ?
                                                <Table columns={bookingTableColumns} dataSource={bookingHistory} scroll={{ x: true }} /> : null
                                        }
                                    </TabPane>
                                </Tabs>
                            </Spin>
                    }

                </div>
            </Col>
        </Row>
    );
}

export default Profile;