import { Select, Typography } from 'antd';
import '../../../css/defaultSelector.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { filterOptionForSelector } from '../../../js/inputFilters';
import { fetchGroups, groupsSlice } from '../../../reducers/groupsSlice';
const { Option } = Select;
const { Title } = Typography;

function FacultySelector() {

    const { selectedFacultyId, faculties } = useAppSelector(state => state.groupsReducer)
    const { setSectedFaculty } = groupsSlice.actions;
    const dispatch = useAppDispatch();


    function onFacultyChange(selectedFacultyId: string) {
        dispatch(setSectedFaculty(selectedFacultyId));
        dispatch(fetchGroups(selectedFacultyId));
    };

    return (
        <>
            <Title level={5}>Факультет:</Title>
            <Select
                showSearch
                defaultValue={selectedFacultyId}
                className="defaultSelector"
                placeholder="Название факультета"
                optionFilterProp="children"
                onChange={onFacultyChange}
                filterOption={(input, option) => filterOptionForSelector(input, option)}
            >
                {
                    faculties.map(faculty => {
                        return <Option key={faculty.id} value={faculty.id}>{faculty.name}</Option>
                    })
                }
            </Select>
        </>
    )
}

export default FacultySelector;