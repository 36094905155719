import { DefaultOptionType } from "antd/lib/select";
import { IAuditory, IGroup, IProfessor } from "../models/Models";

export function filterOptionForSelector(input: string, option: DefaultOptionType | undefined): boolean {
    return (option!.children as unknown as string).toLowerCase().includes(input.trim().toLowerCase())
}

export function getFilteredArray(array: IGroup[] | IAuditory[], input: string): IGroup[] | IAuditory[] {
    return array.filter(element => element.name.toLowerCase().includes(input.trim().toLowerCase()) === true)
}

export function getFilteredArrayOfProfessors(array: IProfessor[], input: string): IProfessor[] {
    return array.filter(element => element.fullName.toLowerCase().includes(input.trim().toLowerCase()) === true)
}