import React, { useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from 'react';
const { Title } = Typography;

function AuthMobile() {

    const [accessToken, setAccessToken] = useState<string>();

    useEffect(() => {
        const currentURL = new URL(window.location.href);
        setAccessToken(encodeURIComponent(currentURL.searchParams.get("token") as string));
    }, [])

    return (
        <Content style={{ padding: '0 24px' }}>
            <Row justify="center" align="middle">
                <Col xs={24} sm={16} md={12} lg={8}>
                    <div className={"home_content-container"}>
                        <Title level={2}>Войти через мобильное приложение</Title>
                        <Button type="primary" style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}
                            onClick={() => {
                                window.location.href = `mobile://intime.ru/auth/success?token=${accessToken}`;
                            }}>
                            Продолжить в приложении
                        </Button>
                    </div>
                </Col>
            </Row>
        </Content>
    )
}

export default AuthMobile;