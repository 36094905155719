import React, { useEffect } from "react";
import { Button, Col, Row, Spin, Typography } from "antd";
import ProfessorsSelectorContainer from "./components/ProfessorsSelector";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchProfessors } from "../../reducers/professorsSlice";
import { useNavigate } from "react-router-dom";
import { schedulePaths } from "../../common/URLPaths";
const { Title } = Typography;

function Professors() {

    const { selectedProfessorId, isLoading, professors, } = useAppSelector(state => state.professorsReducer)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchProfessors())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={16}>
                <Spin size="large" spinning={isLoading}>
                    <div className={"groups_content-container"}>
                        <Title level={2}>Расписание преподавателей</Title>
                        <ProfessorsSelectorContainer />

                        {
                            selectedProfessorId != null ?
                                <Button type="primary" size={"large"}
                                    style={{ marginTop: 32 }} onClick={() => {
                                        navigate(schedulePaths.getInitPathProfessor(selectedProfessorId, professors));
                                    }}>
                                    Показать расписание
                                </Button> : null
                        }
                    </div>
                </Spin>
            </Col>
        </Row>
    );

}

export default Professors;