import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handle } from "../common/ErrorHandler";
import URLPaths from "../common/URLPaths";
import { IFaculty, IGroup } from "../models/Models"
import Network from "../services/Network";

interface GroupsState {
    selectedFacultyId: string | null,
    selectedGroupId: string | null,
    faculties: IFaculty[],
    groups: IGroup[],
    isLoading: boolean
}

const initialState: GroupsState = {
    selectedFacultyId: null,
    selectedGroupId: null,
    faculties: [],
    groups: [],
    isLoading: false
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setSectedFaculty(state, action: PayloadAction<string>) {
            state.selectedFacultyId = action.payload;
            state.selectedGroupId = null;
        },
        setSelectedGroup(state, action: PayloadAction<string>) {
            state.selectedGroupId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFaculties.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchFaculties.fulfilled, (state, action) => {
            state.isLoading = false;
            state.faculties = action.payload as IFaculty[];
        })
        builder.addCase(fetchFaculties.rejected, (state) => {
            state.isLoading = false;
            handle();
        })

        builder.addCase(fetchGroups.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchGroups.fulfilled, (state, action) => {
            state.isLoading = false;
            state.groups = action.payload as IGroup[];
        })
        builder.addCase(fetchGroups.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
    }
})

export const fetchFaculties = createAsyncThunk(
    "auditories/fetchFaculties",
    async function () {
        const response = await Network.request(URLPaths.faculties);
        return response.data;
    }
);

export const fetchGroups = createAsyncThunk(
    "auditories/fetchGroups",
    async function (selectedFaculty: string) {
        const response = await Network.request(URLPaths.groups(selectedFaculty));
        return response.data;
    }
);

export default groupsSlice.reducer;