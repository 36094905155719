import { Space, Input, Spin, Divider } from 'antd';
import { useEffect, useState } from 'react';
import BuildingSelectorContainer from "../../../../Auditories/components/BuildingsSelector";
import { SearchOutlined } from '@ant-design/icons';
import '../css/checkBoxGroup.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { combinedScheduleDrawerSlice } from '../../../../../reducers/combinedScheduleDrawerSlice';
import { fetchBuildings } from '../../../../../reducers/auditoriesSlice';
import { IAuditory } from '../../../../../models/Models';
import { getFilteredArray } from '../../../../../js/inputFilters';
import { getMatchParams } from '../../../../../js/getMatchParams';

function AuditoriesTab() {

    const { selectedAuditories } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { isLoading, auditories } = useAppSelector(state => state.auditoriesReducer);
    const { setSelectedAuditories, unsetSelectedAuditories } = combinedScheduleDrawerSlice.actions;
    const dispatch = useAppDispatch();

    const [buildingFilter, setFilter] = useState('')

    useEffect(() => {
        dispatch(fetchBuildings());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function inputChangeHandle(e: any) {
        setFilter(e.target.value)
    }

    function onChangeCheckbox(e: any, value: IAuditory) {
        if (e.target.checked) {
            dispatch(setSelectedAuditories(value));
        }
        else {
            dispatch(unsetSelectedAuditories(value));
        }
    }

    return (
        <div style={{ marginTop: '18px' }}>
            <BuildingSelectorContainer />
            <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    isLoading ? <Spin style={{ marginTop: '30px' }} /> : auditories.length > 0 ?
                        <>
                            <Input
                                placeholder='Поиск'
                                prefix={<SearchOutlined />}
                                style={{ marginBottom: '16px' }}
                                onChange={(e) => inputChangeHandle(e)}
                            />

                            <Space
                                direction='vertical'
                                size={16}
                                style={{
                                    overflowY: 'auto',
                                    height: '518px'
                                }}
                            >
                                {getFilteredArray(auditories, buildingFilter).map(auditory => {
                                    const idAndName = `${auditory.id}!${auditory.name}`;

                                    return (
                                        <label key={idAndName} className='labelCheckbox'>
                                            <div>{auditory.name}</div>
                                            <input
                                                type='checkbox'
                                                checked={selectedAuditories.some(selectedVal => selectedVal.id === auditory.id)}
                                                onChange={(e) => onChangeCheckbox(e, auditory)}
                                                disabled={getMatchParams().scheduleId === auditory.id}
                                                className='custom-checkbox'
                                            />
                                        </label>
                                    )
                                })}
                            </Space>
                        </> : null
                }
            </div>
        </div>
    )
}

export default AuditoriesTab;