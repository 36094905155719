import Modal from "antd/es/modal/Modal";
import { dateFormats, lessonColors, lessonTypes } from "../../../common/Constants";
import Title from "antd/es/typography/Title";
import { Tag, Typography } from 'antd';
import moment from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { scheduleSlice } from "../../../reducers/scheduleSlice";

function SelectedLessonModal() {

    const { selectedLesson, isDetailsModalShown } = useAppSelector(state => state.scheduleReducer);
    const { setIsDetailsModalShown } = scheduleSlice.actions;
    const dispatch = useAppDispatch();

    return (
        <Modal
            title={selectedLesson != null ? selectedLesson.title : ""}
            open={isDetailsModalShown}
            footer={null}
            onCancel={() => {
                dispatch(setIsDetailsModalShown(false))
            }}
        >
            {
                selectedLesson != null ?
                    <>
                        <Tag color={lessonColors[selectedLesson.lessonType as keyof typeof lessonColors]}
                            style={{ marginBottom: 16 }}>
                            {lessonTypes[selectedLesson.lessonType as keyof typeof lessonTypes]}
                        </Tag>
                        <Title
                            level={5}>{selectedLesson.groups.length > 1 ? "Группы" : "Группа"}</Title>
                        {selectedLesson.groups.map(group => group.name).join(", ")}
                        <Title level={5}>Преподаватель</Title>

                        {lessonColors[selectedLesson.lessonType as keyof typeof lessonColors] === lessonColors.BOOKING ?
                            <Typography>{selectedLesson.professor.fullName}</Typography>
                            :
                            <a className={"ant-typography"}
                                href={`/schedule/professor/${selectedLesson.professor.id}?name=${selectedLesson.professor.fullName}`}>{selectedLesson.professor.fullName}</a>
                        }

                        <Title level={5}>Аудитория</Title>
                        {
                            (selectedLesson.audience.id == null) ?
                                <>{selectedLesson.audience.name}</> :
                                <a className={"ant-typography"}
                                    href={`/schedule/audience/${selectedLesson.audience.id}?name=${selectedLesson.audience.name}`}>
                                    {selectedLesson.audience.name}
                                </a>
                        }

                        <br />
                        <div style={{
                            fontSize: 18,
                            marginTop: 24,
                            fontWeight: 500
                        }}>{moment(selectedLesson.starts * 1000).format(dateFormats.HOUR_MINUTES)} – {moment(selectedLesson.ends * 1000).format(dateFormats.HOUR_MINUTES)}</div>
                    </> : null
            }

        </Modal>
    )
}

export default SelectedLessonModal;
