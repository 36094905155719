import React, { useEffect } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { finishLogin } from "../../reducers/profileSlice";

const { Title, Paragraph } = Typography;

function FinishLogin() {

    useEffect(() => {
        finishLogin();
    }, [])

    return (
        <Row justify="center" align="middle">
            <Col xs={24} sm={16} md={12} lg={8}>
                <div className={"home_content-container"}>
                    <Title level={2}>Подождите...</Title>
                    <Paragraph>
                        Вы будете перенаправлены через несколько секунд.
                    </Paragraph>
                    <Spin style={{ display: "block", margin: "0 auto" }} size="large" />
                </div>
            </Col>
        </Row>
    );
}

export default FinishLogin;