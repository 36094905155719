import { Avatar } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { ReactComponent as Logo } from "../logo.svg";
import { MenuOutlined } from "@ant-design/icons";
import { useAppSelector } from "../hooks/redux";
import avatarPlaceholder from '../images/avatar-placeholder.png';

function InTimeMenu() {
    const { profile } = useAppSelector(state => state.profileReducer);

    return (
        <Menu
            theme="dark"
            mode="horizontal"
            overflowedIndicator={<MenuOutlined />}
            selectedKeys={[useLocation().pathname]}
        >
            <Menu.Item className={"logo"}>
                <Link to={"/"}>
                    <Logo style={{ marginTop: 12, marginRight: 8 }} />
                    TSU.InTime
                </Link>
            </Menu.Item>
            <Menu.Item key={"/"}>
                <Link to={"/"}>
                    Главная
                </Link>
            </Menu.Item>
            <Menu.Item key={"/groups"}>
                <Link to={"/groups"}>
                    Группы
                </Link>
            </Menu.Item>
            <Menu.Item key={"/professors"}>
                <Link to={"/professors"}>
                    Преподаватели
                </Link>
            </Menu.Item>
            <Menu.Item key={"/auditories"}>
                <Link to={"/auditories"}>
                    Аудитории
                </Link>
            </Menu.Item>

            {
                profile ?
                    <Menu.Item style={{ marginLeft: "auto" }} key={"/profile"}>
                        <Link to={"/profile"}>
                            <Avatar style={{ marginRight: 10 }} size="small"
                                src={profile.avatarUrl ? profile.avatarUrl : avatarPlaceholder} />
                            {profile.firstName}
                        </Link>
                    </Menu.Item>
                    :
                    <Menu.Item style={{ marginLeft: "auto" }} key={"/login"}>
                        <Link to={"/login"} >
                            Вход
                        </Link >
                    </Menu.Item>
            }
        </Menu>
    )
}

export default InTimeMenu;