import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auditoriesReducer from '../reducers/auditoriesSlice';
import profileReducer from "../reducers/profileSlice";
import groupsReducer from "../reducers/groupsSlice";
import professorsReducer from "../reducers/professorsSlice";
import scheduleReducer from "../reducers/scheduleSlice";
import bookAuditorySlice from "../reducers/bookAuditorySlice";
import combinedScheduleDrawerSlice from "../reducers/combinedScheduleDrawerSlice";
const rootReducer = combineReducers({
    auditoriesReducer,
    profileReducer,
    groupsReducer,
    professorsReducer,
    scheduleReducer,
    bookAuditorySlice,
    combinedScheduleDrawerSlice
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    })
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];