import { Select, Typography } from 'antd';
import '../../../css/defaultSelector.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { filterOptionForSelector } from '../../../js/inputFilters';
import { groupsSlice } from '../../../reducers/groupsSlice';
const { Option } = Select;
const { Title } = Typography;

function GroupSelector() {

    const { groups, selectedGroupId } = useAppSelector(state => state.groupsReducer)
    const { setSelectedGroup } = groupsSlice.actions;
    const dispatch = useAppDispatch();

    function onGroupChange(selectedGroupId: string) {
        dispatch(setSelectedGroup(selectedGroupId));
    }


    return (

        groups.length > 0 ?
            <>
                <Title level={5} style={{ marginTop: 32 }}>Группа:</Title>
                <Select
                    showSearch
                    value={selectedGroupId}
                    className="defaultSelector"
                    placeholder="Номер группы"
                    optionFilterProp="children"
                    onChange={onGroupChange}
                    filterOption={(input, option) => filterOptionForSelector(input, option)}
                >
                    {
                        groups.map(group => {
                            return <Option key={group.id} value={group.id}>{group.name}</Option>
                        })
                    }
                </Select>
            </> : null
    )
}

export default GroupSelector;