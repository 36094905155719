import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuditory, IGroup, IProfessor } from "../models/Models";
import { RootState } from "../store/store";
import { fetchSchedule, fetchScheduleCombined } from "./scheduleSlice";

export interface CombinedScheduleDrawer {
    isVisible: boolean,
    selectedGroups: IGroup[],
    selectedAuditories: IAuditory[],
    selectedProfessors: IProfessor[],
    numOfChoosen: number,
    professorFilter: string,

    activeGroups: IGroup[],
    activeAuditories: IAuditory[],
    activeProfessors: IProfessor[],
}

const initialState: CombinedScheduleDrawer = {
    isVisible: false,
    selectedGroups: [],
    selectedAuditories: [],
    selectedProfessors: [],
    numOfChoosen: 0,
    professorFilter: '',

    activeGroups: [],
    activeAuditories: [],
    activeProfessors: [],
}

export const combinedScheduleDrawerSlice = createSlice({
    name: 'combinedScheduleDrawer',
    initialState,
    reducers: {
        clearDrawerSlice: () => initialState,
        setIsVisible(state, action: PayloadAction<boolean>) {
            state.isVisible = action.payload;
        },
        saveActiveArrays(state) {
            state.activeAuditories = state.selectedAuditories;
            state.activeGroups = state.selectedGroups;
            state.activeProfessors = state.selectedProfessors;
        },
        resetActiveArrays(state) {
            state.selectedAuditories = state.activeAuditories;
            state.selectedGroups = state.activeGroups;
            state.selectedProfessors = state.activeProfessors;
            state.numOfChoosen = state.selectedAuditories.length + state.selectedGroups.length + state.selectedProfessors.length;
        },
        setSelectedGroups(state, action: PayloadAction<IGroup>) {
            state.selectedGroups = [...state.selectedGroups, action.payload]
            state.numOfChoosen += 1;
        },
        setSelectedAuditories(state, action: PayloadAction<IAuditory>) {
            state.selectedAuditories = [...state.selectedAuditories, action.payload]
            state.numOfChoosen += 1;
        },
        setSelectedProfessors(state, action: PayloadAction<IProfessor>) {
            state.selectedProfessors = [...state.selectedProfessors, action.payload]
            state.numOfChoosen += 1;
        },
        unsetSelectedGroups(state, action: PayloadAction<IGroup>) {
            state.selectedGroups = state.selectedGroups.filter(group => group.id !== action.payload.id);
            state.numOfChoosen -= 1;
        },
        unsetSelectedAuditories(state, action: PayloadAction<IAuditory>) {
            state.selectedAuditories = state.selectedAuditories.filter(auditory => auditory.id !== action.payload.id);
            state.numOfChoosen -= 1;
        },
        unsetSelectedProfessors(state, action: PayloadAction<IProfessor>) {
            state.selectedProfessors = state.selectedProfessors.filter(professor => professor.id !== action.payload.id);
            state.numOfChoosen -= 1;
        },
        setProfessorFilter(state, action: PayloadAction<string>) {
            state.professorFilter = action.payload;
        },
    },

})

export const unsetAndFetchCombinedSchedule = createAsyncThunk(
    "scheduleDrawer/unsetAndFetchCombinedSchedule",
    async function (data: { choosen: IAuditory | IGroup | IProfessor, unsetThunkCreator: Function }, thunkAPI) {

        thunkAPI.dispatch(data.unsetThunkCreator(data.choosen));
        const state = thunkAPI.getState() as RootState;

        const combinedScheduleState = state.combinedScheduleDrawerSlice;
        const scheduleState = state.scheduleReducer;

        if (scheduleState.isCombinedScheduleActive) {

            if (combinedScheduleState.numOfChoosen) {
                thunkAPI.dispatch(fetchScheduleCombined())
            }
            else {
                thunkAPI.dispatch(fetchSchedule())

            }
        }
    }
)

export default combinedScheduleDrawerSlice.reducer;