import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handle } from "../common/ErrorHandler";
import URLPaths from "../common/URLPaths";
import Network from "../services/Network";
import moment from "moment-timezone";
import { notifySuccess } from "../common/NotificationUtility";
import { IBookingFormField, ILessonTime, INewBookingRequestFormField, IParamBookAuditoryDTO } from "../models/Models";
import { dateFormats } from "../common/Constants";

export interface BookAuditoryState {
    isLoading: boolean,
    selectedDate: string,
    selectedTimes: ILessonTime[],
    eventTitle: string,
    participantCount: number,
    additionalFields: IBookingFormField[],
    additionalFieldsValues: INewBookingRequestFormField[]
}

const initialState: BookAuditoryState = {
    isLoading: false,
    selectedDate: '',
    selectedTimes: [],
    eventTitle: '',
    participantCount: 0,
    additionalFields: [],
    additionalFieldsValues: []
}

export const bookAuditorySlice = createSlice({
    name: 'bookAuditory',
    initialState,
    reducers: {
        clearBookAuditory: () => initialState,
        setSelectedDate(state, action: PayloadAction<string>) {
            state.selectedDate = action.payload;
        },
        setEventTitle(state, action: PayloadAction<string>) {
            state.eventTitle = action.payload;
        },
        setParticipantCount(state, action: PayloadAction<number>) {

            state.participantCount = action.payload;
        },
        tabIsChanged(state, action: PayloadAction<{ selectedDate: string, selectedTimes: ILessonTime[] }>) {
            state.selectedDate = action.payload.selectedDate;
            state.selectedTimes = action.payload.selectedTimes;
        },
        toggleTime(state, action: PayloadAction<ILessonTime>) {
            let times = [...state.selectedTimes];
            let index = times.map(time => time.starts).indexOf(action.payload.starts);
            if (index !== -1) {
                times.splice(index, 1);
            } else {
                times.push(action.payload);
            }
            state.selectedTimes = times;
        },
        setAdditionalFieldsValues(state, action: PayloadAction<{ fieldId: string, value: string }>) {
            if(action.payload.value === ''){
                state.additionalFieldsValues.find(field => field.fieldId === action.payload.fieldId)!.value = null;
            }
            else{
                state.additionalFieldsValues.find(field => field.fieldId === action.payload.fieldId)!.value = action.payload.value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBookAuditory.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchBookAuditory.fulfilled, (state) => {
            state.isLoading = false;
            state.selectedDate = '';
            state.selectedTimes = [];
            notifySuccess("Успешно", "Заявка на бронь аудитории была отправлена");
        })
        builder.addCase(fetchBookAuditory.rejected, (state) => {
            state.isLoading = false;
            handle();
        })

        builder.addCase(fetchAdditionalFields.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchAdditionalFields.fulfilled, (state, action) => {
            state.isLoading = false;
            state.additionalFields = action.payload;

            state.additionalFieldsValues.push(
                ...action.payload.map((field: IBookingFormField) => {
                    const newNewBookingRequestFormField: INewBookingRequestFormField = {
                        fieldId: field.id, value: null
                    }
                    return newNewBookingRequestFormField
                }))
        })
        builder.addCase(fetchAdditionalFields.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
    }
})

export const fetchAdditionalFields = createAsyncThunk(
    "auditories/fetchAdditionalFields",
    async function (audienceId: string) {

        const response = await Network.request(URLPaths.bookingFields(audienceId), "GET")
        return response.data;
    }
);

export const fetchBookAuditory = createAsyncThunk(
    "auditories/fetchBookAuditory",
    async function (data: IParamBookAuditoryDTO) {

        const params: any = {
            audienceId: data.auditoryID,
            date: moment(data.selectedDate).format(dateFormats.YEAR_MONTH_DAY),
            title: data.eventTitle,
            participantCount: data.participantCount,
            lessons: data.selectedTimes.map(time => {
                return {
                    lessonNumber: data.lessonTimes.map(lessonTime => lessonTime.starts).indexOf(time.starts) + 1,
                    start: time.starts,
                    end: time.ends
                }
            }),
            additionalFields: data.additionalFields
        }
        const response = await Network.request(URLPaths.bookings, "POST", {}, params)
        return response.data;
    }
);

export default bookAuditorySlice.reducer;