import { useEffect } from "react";
import { Col, Modal, Row, Spin, Tag } from 'antd';
import moment from "moment-timezone";
import 'moment/locale/ru';
import './schedule.scss';
import { useNavigate } from "react-router-dom";
import SelectedLessonModal from "./components/SelectedLessonModal";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { closeNetwork, scheduleSlice, setScheduleState } from "../../reducers/scheduleSlice";
import { dateFormats, lessonColors } from "../../common/Constants";
import HeaderOfSchedule from "./components/HeaderOfSchedule";
import ScheduleBar from "./components/ScheduleBar";
import BookAuditory from "./components/BookAuditory";
import CombinedScheduleDrawer from "./components/CombinedScheduleDrawer/CombinedScheduleDrawer";
import { ILessonTime } from "../../models/Models";
import { getMatchParams } from "../../js/getMatchParams";
import { schedulePaths } from "../../common/URLPaths";
import { setHashParam } from "./js/schedule-scripts";
import { bookAuditorySlice } from "../../reducers/bookAuditorySlice";
import { combinedScheduleDrawerSlice } from "../../reducers/combinedScheduleDrawerSlice";

function Schedule() {

    const { schedule, isLoadingSchedule, isBookingModalShown, startDate, endDate,
        shouldToRedirect, shouldToRedirectCombined, paramsForURL } = useAppSelector(state => state.scheduleReducer);

    const { setIsDetailsModalShown, setSelectedLesson, setIsBookingModalShown,
        setIsCombinedScheduleActive, setShouldToRedirect, setShouldToRedirectCombined } = scheduleSlice.actions;
        
    const { numOfChoosen } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { clearDrawerSlice } = combinedScheduleDrawerSlice.actions;
    const { clearBookAuditory } = bookAuditorySlice.actions;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const matchParams = getMatchParams();

    moment.locale('ru');
    moment.tz.setDefault("Asia/Novosibirsk");

    useEffect(() => {

        if (shouldToRedirect) {
            dispatch(setShouldToRedirect(false));

            setHashParam('startDate', startDate.toISOString());
            setHashParam('endDate', endDate.toISOString());
            navigate(schedulePaths.getSchedulePath())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldToRedirect])

    useEffect(() => {

        if (shouldToRedirectCombined) {
            dispatch(setShouldToRedirectCombined(false));

            setHashParam('startDate', startDate.toISOString());
            setHashParam('endDate', endDate.toISOString());
            navigate(schedulePaths.getSchedulePath(paramsForURL!, numOfChoosen))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldToRedirectCombined])

    useEffect(() => {
        dispatch(setScheduleState())

        return (() => {
            dispatch(closeNetwork())
            dispatch(clearDrawerSlice());
            dispatch(clearBookAuditory())
            dispatch(setIsCombinedScheduleActive({ isCombinedScheduleActive: false, numOfActiveSchedules: 0 }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let lessonTimes: ILessonTime[] = [];

    if (schedule[0] !== null && schedule[0] !== undefined) {
        lessonTimes = schedule[0].lessons.map(lesson => {
            return {
                starts: lesson.starts,
                ends: lesson.ends
            };
        }).filter((value, index, lessonTimes) =>
            index === lessonTimes.findIndex(lessonTime => (
                lessonTime.starts === value.starts && lessonTime.ends === value.ends
            ))
        );
    }

    return (
        <>
            <SelectedLessonModal />
            <CombinedScheduleDrawer />

            <Modal
                title="Забронировать аудиторию"
                open={isBookingModalShown}
                footer={null}
                width={660}
                onCancel={() => {
                    dispatch(setIsBookingModalShown(false));
                }}
            >
                <BookAuditory auditoryID={matchParams.scheduleId} schedule={schedule} />
            </Modal>
            <Row justify="center">
                <Col md={24} lg={20}>
                    <Spin size={"large"} spinning={isLoadingSchedule}>
                        <div className={"schedule_content-container"}>
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
                                <HeaderOfSchedule />
                                <ScheduleBar />
                            </div>
                            <div className={"schedule-table"}>
                                <div className={"schedule-rows"}>
                                    <Row>
                                        {
                                            schedule.map(scheduleColumn => {
                                                return <Col span={4} key={scheduleColumn.date}>
                                                    <div className={"schedule_date-title"}>
                                                        <span>
                                                            {moment(scheduleColumn.date).format(dateFormats.DAY)}
                                                        </span>
                                                        <span>
                                                            {moment(scheduleColumn.date).format(dateFormats.DAY_MONTH)}
                                                        </span>
                                                    </div>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                    {
                                        lessonTimes.map(lessonTime => {
                                            return <>
                                                <div className={"schedule-times"}>
                                                    <span>{moment.unix(lessonTime.starts).format(dateFormats.HOUR_MINUTES)}</span>
                                                    <span>{moment.unix(lessonTime.ends).format(dateFormats.HOUR_MINUTES)}</span>
                                                </div>
                                                <Row className="schedule-row">
                                                    {
                                                        schedule.map(cheduleColumn => {
                                                            return <Col span={4} key={cheduleColumn.date}>
                                                                {
                                                                    cheduleColumn.lessons.filter(lesson => lesson.starts === lessonTime.starts).map(lesson => {
                                                                        return lesson.type === "LESSON" ?
                                                                            <Tag
                                                                                key={lesson.id}
                                                                                color={lessonColors[lesson.lessonType as keyof typeof lessonColors]}
                                                                                onClick={() => {
                                                                                    dispatch(setIsDetailsModalShown(true));
                                                                                    dispatch(setSelectedLesson(lesson))
                                                                                }}>
                                                                                <span
                                                                                    className={"lesson-title"}>{lesson.title}</span>
                                                                                <span
                                                                                    className={"lesson-auditory"}>{lesson.audience.name}</span>
                                                                                <span
                                                                                    className={"lesson-group"}>{lesson.groups.map(group => group.name).join(", ")}</span>
                                                                            </Tag> : null
                                                                    })
                                                                }

                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{
                                marginTop: 16,
                                color: "#8d8d8d",
                                fontSize: 14
                            }}>*В расписании указано томское время (UTC+7:00)
                            </div>
                        </div>
                    </Spin>
                </Col>
            </Row>
        </>
    );
}

export default Schedule;