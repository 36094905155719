import { Space, Input, Spin, Divider } from 'antd';
import React, { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import '../css/checkBoxGroup.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { CombinedScheduleDrawer, combinedScheduleDrawerSlice } from '../../../../../reducers/combinedScheduleDrawerSlice';
import { fetchProfessors } from '../../../../../reducers/professorsSlice';
import { IProfessor } from '../../../../../models/Models';
import { getMatchParams } from '../../../../../js/getMatchParams';
import { getFilteredArrayOfProfessors } from '../../../../../js/inputFilters';

function isFilterOrSelectedProfessorsChanged(prev: CombinedScheduleDrawer, next: CombinedScheduleDrawer) {
    if (JSON.stringify(next.selectedProfessors) !== JSON.stringify(prev.selectedProfessors) || (next.professorFilter !== prev.professorFilter)) {
        return false;
    }
    return true;
}

function ProfessorsTab() {

    const { selectedProfessors, professorFilter } = useAppSelector(state => state.combinedScheduleDrawerSlice, isFilterOrSelectedProfessorsChanged);
    const { isLoading, professors } = useAppSelector(state => state.professorsReducer)
    const { setSelectedProfessors, unsetSelectedProfessors, setProfessorFilter } = combinedScheduleDrawerSlice.actions;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchProfessors())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function inputChangeHandle(e: any) {
        dispatch(setProfessorFilter(e.target.value))
    }

    function onChangeCheckbox(e: any, value: IProfessor) {
        if (e.target.checked) {
            dispatch(setSelectedProfessors(value));
        }
        else {
            dispatch(unsetSelectedProfessors(value));
        }
    }

    return (
        <>
            <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
            <Input
                placeholder='Поиск'
                prefix={<SearchOutlined />}
                style={{ marginBottom: '16px' }}
                onChange={(e) => inputChangeHandle(e)} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    isLoading ? <Spin style={{ marginTop: '30px' }} /> : professors.length > 0 ?
                        <>
                            <Space
                                direction='vertical'
                                size={16}

                                style={{
                                    overflowY: 'auto',
                                    height: '600px'
                                }}
                            >
                                {getFilteredArrayOfProfessors(professors, professorFilter).map(professor => {
                                    const idAndName = `${professor.id}!${professor.fullName}`;

                                    return (
                                        <label key={idAndName} className='labelCheckbox'>
                                            <div>{professor.fullName}</div>
                                            <input
                                                type='checkbox'
                                                checked={selectedProfessors.some(selectedVal => selectedVal.id === professor.id)}
                                                onChange={(e) => onChangeCheckbox(e, professor)}
                                                disabled={getMatchParams().scheduleId === professor.id}
                                                className='custom-checkbox'
                                            />
                                        </label>
                                    )
                                })}
                            </Space>
                        </>
                        : <Spin style={{ marginTop: '30px' }} />
                }
            </div>
        </>
    )
}

export default React.memo(ProfessorsTab);