import { Popover, Tag, Badge } from 'antd';
import 'moment/locale/ru';
import { PrinterOutlined, QuestionCircleOutlined, CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { lessonColors, lessonTypes } from "../../../common/Constants";
import { printSchedule } from '../js/schedule-scripts';
import './css/scheduleBar.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { scheduleSlice } from '../../../reducers/scheduleSlice';
import { combinedScheduleDrawerSlice } from '../../../reducers/combinedScheduleDrawerSlice';
import { getMatchParams } from '../../../js/getMatchParams';

function ScheduleBar() {

    const { schedule, canBook } = useAppSelector(state => state.scheduleReducer);
    const { numOfChoosen } = useAppSelector(state => state.combinedScheduleDrawerSlice)
    const { setIsBookingModalShown } = scheduleSlice.actions;
    const { setIsVisible } = combinedScheduleDrawerSlice.actions;
    const dispatch = useAppDispatch();

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            {getMatchParams().type === "audience" && canBook ?
                <span className='spanButton' onClick={() => {
                    dispatch(setIsBookingModalShown(true));
                }}><CalendarOutlined /> Забронировать</span> : null
            }

            <Badge offset={[-35, 7]} color={'blue'} count={numOfChoosen}>
                <span className='spanButton' onClick={() => { dispatch(setIsVisible(true)) }}><PlusOutlined /> Объединить</span>
            </Badge>
            <span className='spanButton' onClick={() => { printSchedule(schedule) }}><PrinterOutlined /> Распечатать</span>

            <Popover content={
                <div style={{ maxWidth: 200 }}>
                    {
                        Object.entries(lessonTypes).map((item, index) => {
                            return (item[1] !== lessonTypes.EMPTY ? <div key={`${item[0]}+${item[1]}`}>
                                <Tag

                                    style={{
                                        maxWidth: "100%",
                                        whiteSpace: "pre-wrap",
                                        marginBottom: index === Object.entries(lessonTypes).length - 1 ? 0 : 8
                                    }}

                                    color={lessonColors[item[0] as keyof typeof lessonColors]}>{item[1]}
                                </Tag>
                                <br />
                            </div> : null)
                        })
                    }
                </div>
            }>
                <span className='spanButton'><QuestionCircleOutlined /> Что означают цвета?</span>
            </Popover>
        </div >
    )
}

export default ScheduleBar;