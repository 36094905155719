import moment, { Moment } from "moment-timezone";
import queryString from "query-string";
import { localStorageKeys } from "../../../common/Constants";
import { IScheduleColumn } from "../../../models/Models";

function startOfAcademicYear(date: Moment) {
    let currentMonth = date.month() + 1;
    let currentAcademicYear;
    if (currentMonth < 9) {
        currentAcademicYear = date.years() - 1;
    } else {
        currentAcademicYear = date.years();
    }
    return moment([currentAcademicYear, 8, 1]);
};

function firstFullAcademicWeekDate(date: Moment) {
    let academicYearStart = startOfAcademicYear(date);
    let academicYearStartWeekday = academicYearStart.isoWeekday();
    return academicYearStart.add(8 - academicYearStartWeekday, 'days');
}


export function numberOfAcademicWeek(date: Moment) {
    let firstFullWeekDate = firstFullAcademicWeekDate(date);
    let daysFromFirstFullWeek = Math.abs(firstFullWeekDate.diff(date, 'days')) - 1;
    return Math.ceil(daysFromFirstFullWeek / 7) + 1
}

export function setHashParam(key: string, value: string) {
    let parsed = queryString.parse(window.location.hash);
    parsed[key] = value;
    window.location.hash = queryString.stringify(parsed);
}

export function getHashParam(key: string) {
    let parsed = queryString.parse(window.location.hash);
    return parsed[key];
}

export function printSchedule(schedule: IScheduleColumn[]) {
    localStorage.setItem(localStorageKeys.SCHEDULE_TO_PRINT, JSON.stringify(schedule));
    window.open("/schedule/print", "_blank");
}