import { Space, Input, Spin, Divider } from 'antd';
import { useEffect, useState } from 'react';
import FacultySelectorContainer from "../../../../Groups/components/FacultySelector";
import { SearchOutlined } from '@ant-design/icons';
import '../css/checkBoxGroup.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { combinedScheduleDrawerSlice } from '../../../../../reducers/combinedScheduleDrawerSlice';
import { fetchFaculties } from '../../../../../reducers/groupsSlice';
import { IGroup } from '../../../../../models/Models';
import { getFilteredArray } from '../../../../../js/inputFilters';
import { getMatchParams } from '../../../../../js/getMatchParams';

function GroupsTab() {

    const { selectedGroups } = useAppSelector(state => state.combinedScheduleDrawerSlice);
    const { isLoading, groups } = useAppSelector(state => state.groupsReducer);
    const { setSelectedGroups, unsetSelectedGroups } = combinedScheduleDrawerSlice.actions;
    const dispatch = useAppDispatch();

    const [groupFilter, setGropupFilter] = useState('');

    useEffect(() => {
        dispatch(fetchFaculties());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function inputChangeHandle(e: any) {
        setGropupFilter(e.target.value)
    }

    function onChangeCheckbox(e: any, value: IGroup) {
        if (e.target.checked) {
            dispatch(setSelectedGroups(value))
        }
        else {
            dispatch(unsetSelectedGroups(value))
        }
    }

    return (
        <div style={{ marginTop: '18px' }}>
            <FacultySelectorContainer />
            <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    isLoading ? <Spin style={{ marginTop: '30px' }} /> : groups.length > 0 ?
                        <>
                            <Input
                                placeholder='Поиск'
                                prefix={<SearchOutlined />}
                                style={{ marginBottom: '16px' }}
                                onChange={(e) => inputChangeHandle(e)} />
                            <Space
                                direction='vertical'
                                size={16}
                                style={{
                                    overflowY: 'auto',
                                    height: '518px'
                                }}
                            >
                                {getFilteredArray(groups, groupFilter).map(group => {
                                    const idAndName = `${group.id}!${group.name}`;
                                    return (

                                        <label key={idAndName} className='labelCheckbox'>
                                            {group.name}
                                            <input
                                                type='checkbox'
                                                checked={selectedGroups.some(selectedVal => selectedVal.id === group.id)}
                                                onChange={(e) => onChangeCheckbox(e, group)}
                                                disabled={getMatchParams().scheduleId === group.id}
                                                className='custom-checkbox'
                                            />
                                        </label>
                                    )
                                })}
                            </Space>
                        </> : null
                }
            </div>
        </div>
    )
}

export default GroupsTab;