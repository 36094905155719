import { Select, Typography } from "antd";
import '../../../css/defaultSelector.scss';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { filterOptionForSelector } from "../../../js/inputFilters";
import { auditoriesSlice, fetchAuditories } from "../../../reducers/auditoriesSlice";
const { Option } = Select;
const { Title } = Typography;

function BuildingsSelector() {

    const { selectedBuildingId, buildings } = useAppSelector(state => state.auditoriesReducer);
    const { setSelectedBuilding } = auditoriesSlice.actions;
    const dispatch = useAppDispatch();

    function onBuildingChange(selectedBuilding: string) {
        dispatch(setSelectedBuilding(selectedBuilding));
        dispatch(fetchAuditories(selectedBuilding));
    };

    return (
        <>
            <Title level={5}>Корпус:</Title>
            <Select
                showSearch
                defaultValue={selectedBuildingId}
                className="defaultSelector"
                placeholder="Номер корпуса"
                optionFilterProp="children"
                onChange={onBuildingChange}
                filterOption={(input, option) => filterOptionForSelector(input, option)}
            >
                {
                    buildings.map(building => {
                        return <Option key={building.id} value={building.id}>{building.name}</Option>
                    })
                }
            </Select>
        </>
    )
}


export default BuildingsSelector;