import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handle } from "../common/ErrorHandler";
import URLPaths from "../common/URLPaths";
import { IProfessor } from "../models/Models"
import Network from "../services/Network";

interface ProfessorsState {
    professors: IProfessor[],
    selectedProfessorId: string | null,
    isLoading: boolean
}

const initialState: ProfessorsState = {
    professors: [],
    selectedProfessorId: null,
    isLoading: false
}

export const professorsSlice = createSlice({
    name: 'professors',
    initialState,
    reducers: {
        setSelectedProfessor(state, action: PayloadAction<string>) {
            state.selectedProfessorId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfessors.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchProfessors.fulfilled, (state, action) => {
            state.isLoading = false;
            state.professors = action.payload as IProfessor[];
        })
        builder.addCase(fetchProfessors.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
    }
})

export const fetchProfessors = createAsyncThunk(
    "auditories/fetchProfessors",
    async function () {
        const response = await Network.request(URLPaths.professors);
        return response.data;
    }
);

export default professorsSlice.reducer;