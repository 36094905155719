import Constants, { localStorageKeys } from "../common/Constants";
import axios, { Method } from "axios";
import URLPaths from "../common/URLPaths";
import { clearLocalStorageAndRedirectToLogin } from "../js/localStorageActions";


class NetworkService {
    controller = new AbortController();

    instance = axios.create({
        baseURL: Constants.apiBaseURL,
        signal: this.controller.signal
    });
    constructor() {
        this.setupInterceptors()
    }

    request(url: string, method: string = "GET", params: any = null, data: any = null) {
        return this.instance.request({
            method: method as Method,
            url: url,
            params: params,
            data: data
        })
    }

    setupInterceptors() {
        this.instance.interceptors.request.use(
            config => {

                let token;

                switch (config.url) {
                    case URLPaths.refreshToken:
                        token = localStorage.getItem(localStorageKeys.TOKEN_REFRESH);
                        break;
                    default:
                        token = localStorage.getItem(localStorageKeys.TOKEN_ACCESS);
                        break;
                }
                
                if (token) {
                    config.headers!["Authorization"] = "Bearer " + token;
                }

                return config;
            },
            error => {
                Promise.reject(error);
            });

        this.instance.interceptors.response.use((response) => {
            return response
        },
            async (error) => {
                const originalRequest = error.config;
                if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== URLPaths.refreshToken) {
                    originalRequest._retry = true;
                    let result = await this.refreshToken();
                    localStorage.setItem(localStorageKeys.TOKEN_ACCESS, result.data.accessToken);
                    localStorage.setItem(localStorageKeys.TOKEN_REFRESH, result.data.refreshToken);
                    return this.instance(originalRequest);
                } else if (originalRequest.url === URLPaths.refreshToken) {
                    clearLocalStorageAndRedirectToLogin();
                }
                return Promise.reject(error);
            });
    }

    async refreshToken() {
        return await this.request(URLPaths.refreshToken, "POST");
    }

    cancel() {
        this.controller.abort()
        this.controller = new AbortController()
        this.instance = axios.create({
            baseURL: Constants.apiBaseURL,
            signal: this.controller.signal
        });
        this.setupInterceptors()
    }
}

const Network = new NetworkService();
export default Network;