export function getIdFromPathname() {
    let pathname = window.location.pathname.split('/');
    return pathname[pathname.length - 1];
}

export function getTypeFromPathname() {
    let pathname = window.location.pathname.split('/');
    let type = pathname[pathname.length - 2];

    switch (type) {
        case 'group':
            return 'groupIds'
        case 'professor':
            return 'professorIds'
        case 'audience':
            return 'audienceIds'
        default:
            return ''
    }
}