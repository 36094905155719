import { Select, Typography } from "antd";
import '../../../css/defaultSelector.scss';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { filterOptionForSelector } from "../../../js/inputFilters";
import { auditoriesSlice } from "../../../reducers/auditoriesSlice";
const { Title } = Typography;
const { Option } = Select;

function AuditoriesSelector() {

    const { selectedAuditoryId, auditories } = useAppSelector(state => state.auditoriesReducer);
    const { setSelectedAuditory } = auditoriesSlice.actions;
    const dispatch = useAppDispatch();

    function onAuditoryChange(selectedAuditory: string) {
        dispatch(setSelectedAuditory(selectedAuditory));
    }

    return (

        auditories.length > 0 ?
            <>
                <Title level={5} style={{ marginTop: 32 }}>Аудитория:</Title>
                <Select
                    showSearch
                    value={selectedAuditoryId}
                    className="defaultSelector"
                    placeholder="Номер аудитории"
                    optionFilterProp="children"
                    onChange={onAuditoryChange}
                    filterOption={(input, option) => filterOptionForSelector(input, option)}
                >
                    {
                        auditories.map(auditory => {
                            return <Option key={auditory.id} value={auditory.id}>{auditory.name}</Option>
                        })
                    }
                </Select>
            </> : null
    )
}

export default AuditoriesSelector;
