import { Input, Switch, Button } from "antd";
import { MailOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { profileSlice, saveData } from "../../../reducers/profileSlice";

function NotificationTab() {

    const { isSubscribedForUpdates, subscriptionEmail, canSubscribe, isSaveButtonDisabled } = useAppSelector(state => state.profileReducer);
    const { updateButton, setIsSubscribedForUpdates, setSubscribtionEmail } = profileSlice.actions;
    const dispatch = useAppDispatch();


    function saveDataHandle() {
        dispatch(saveData({ isSubscribedForUpdates, subscriptionEmail }))
    }

    return (
        canSubscribe ?
            <div className="notifications-options-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Switch checked={isSubscribedForUpdates}
                        onChange={isChecked => {
                            dispatch(setIsSubscribedForUpdates(isChecked));
                            dispatch(updateButton({ isSubscribedForUpdates, subscriptionEmail }));
                        }}
                        style={{ marginRight: 8 }} /> Получать уведомления об изменениях в расписании
                </div>
                <Input className="email-input"
                    placeholder="E-mail для рассылки"
                    prefix={<MailOutlined />}
                    value={subscriptionEmail || ''}
                    onChange={event => {
                        dispatch(setSubscribtionEmail(event.target.value));
                        dispatch(updateButton({ isSubscribedForUpdates, subscriptionEmail }));
                    }}
                    disabled={!isSubscribedForUpdates} />
                <Button type="primary"
                    onClick={saveDataHandle}
                    disabled={isSaveButtonDisabled}>Сохранить</Button>
            </div> : null
    )
}

export default NotificationTab;