import React, { useEffect } from "react";
import "./auditories.scss";
import { Button, Col, Row, Spin, Typography } from 'antd';
import BuildingsSelectorContainer from "./components/BuildingsSelector";
import AuditoriesSelectorContainer from "./components/AuditoriesSelector";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { fetchBuildings } from "../../reducers/auditoriesSlice";
import { useNavigate } from "react-router-dom";
import { schedulePaths } from "../../common/URLPaths";

const { Title } = Typography;

function Auditories() {
    
    const { selectedBuildingId, selectedAuditoryId, auditories, isLoading } = useAppSelector(state => state.auditoriesReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchBuildings());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={16}>
                <Spin size="large" spinning={isLoading}>
                    <div className={"auditories_content-container"}>
                        <Title level={2}>Расписание аудиторий</Title>
                        <BuildingsSelectorContainer />
                        <AuditoriesSelectorContainer />

                        {
                            selectedBuildingId != null && selectedAuditoryId != null ?
                                <Button type="primary" size={"large"}
                                    style={{ marginTop: 32 }} onClick={() => {
                                        navigate(schedulePaths.getInitPathAudience(selectedAuditoryId, auditories))
                                    }}>
                                    Показать расписание
                                </Button> : null
                        }
                    </div>
                </Spin>
            </Col>
        </Row >
    );
}

export default Auditories;