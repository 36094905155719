import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handle } from "../common/ErrorHandler";
import URLPaths from "../common/URLPaths";
import { IAuditory, IBuilding } from "../models/Models"
import Network from "../services/Network";

interface AuditoriesState {
    selectedBuildingId: string | null;
    selectedAuditoryId: string | null;
    buildings: IBuilding[];
    auditories: IAuditory[];
    isLoading: boolean;
}

const initialState: AuditoriesState = {
    selectedBuildingId: null,
    selectedAuditoryId: null,
    buildings: [],
    auditories: [],
    isLoading: false
}

export const auditoriesSlice = createSlice({
    name: 'auditories',
    initialState,
    reducers: {
        setSelectedBuilding(state, action: PayloadAction<string>) {
            state.selectedBuildingId = action.payload;
            state.selectedAuditoryId = null;
        },
        setSelectedAuditory(state, action: PayloadAction<string>) {
            state.selectedAuditoryId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBuildings.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchBuildings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.buildings = action.payload as IBuilding[];
        })
        builder.addCase(fetchBuildings.rejected, (state) => {
            state.isLoading = false;
            handle();
        })

        builder.addCase(fetchAuditories.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchAuditories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.auditories = action.payload as IAuditory[];
        })
        builder.addCase(fetchAuditories.rejected, (state) => {
            state.isLoading = false;
            handle();
        })
    }
})

export const fetchBuildings = createAsyncThunk(
    "auditories/fetchBuildings",
    async function () {
        const response = await Network.request(URLPaths.buildings);
        return response.data;
    }
);

export const fetchAuditories = createAsyncThunk(
    "auditories/fetchAuditories",
    async function (selectedBuildingId : string) {
        const response = await Network.request(URLPaths.audiences(selectedBuildingId));
        return response.data;
    }
);


export default auditoriesSlice.reducer;